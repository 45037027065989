
import React from 'react';
import './works.scss';

export default function Works() {
  return (

    <div className="works" id="works">
      <h1 className='headingDesign'> ভিডিও (Video) </h1>


      <div className='iframeDesign1'>
        <iframe
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F348476968822348%2Fvideos%2F307291404941896%2F&show_text=false&width=560"
          width="600"
          height="350"
          style={{ border: "none", overflow: "hidden" }}
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
          title="Embedded Facebook Video 2"
        ></iframe>


        <iframe
          className='iframeDesign'
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100064794520086%2Fvideos%2F1092314158495971%2F&show_text=false&width=560"
          width="600"
          height="350"
          style={{ border: "none", overflow: "hidden" }}
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
          title="Embedded Facebook Video"
        ></iframe>

      </div>
    </div>
  );
}
