import "./menu.scss";

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu "+(menuOpen && "active")}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a className="1stDesign" href="#intro">সূচনা </a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#portfolio">মন্দির সম্পর্কিত কাহিনী</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#works">ভিডিও</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#testimonials">মন্দির এর কিছু ছবি</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#contact">দান করুন</a>
        </li>
      </ul>
    </div>
  );
}