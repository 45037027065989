import "./intro.scss";
import { useEffect, useRef } from "react";
import Typed from 'typed.js';

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    const options = {
      showCursor: true,
      backDelay: 1000,
      backSpeed: 60,
      typeSpeed: 60,
      loop: true, // Set loop to true

      strings: ["সন্দীপন জানা - ৯৭৩২৭৮৬৯০৭", "সৌমেন সামন্ত - ৯৭৩৫৪৫৪০২৫", "বানেশ্বর জানা - ৮০১৬৪৬৪০৭৬", "বাবুল পাত্র - ৯৭৩৩৮১৫৫৩৭", "সৌদ্বীপ পাত্র - ৯৫৪৭৪১১২৬৬", "সুমন পাত্র - ৮১৪৫৩৩৭২৭৫"],
    };
    const typed = new Typed(textRef.current, options);
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="assets/img1.jpeg" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <span className="salDesign">বর্ষ - ১৪৩০ -১৪৩১ </span>
          <span className="salDesign2">সাল - 2024 -2025 </span>
          <h2>উদ্যোক্তা - কালিকাদারী শ্রী শ্রী বাবাবনকুমার (রুদ্রেশ্বর) জিউর মন্দির পুনঃনির্মান কমিটি ও গ্রাম উন্নয়ন কমিটি </h2>
          <h3>
            কমিটি সদস্য -  <span ref={textRef}></span>
          </h3>

          <div className="mainDivDesign">
            <div>অন্নহীনে অন্নদান, বস্ত্র বস্ত্রহীনে,</div>
            <div>তৃষ্ণাতুরে জল দান, ধর্ম ধর্মহীনে,</div>
            <div>মূর্খঙ্গনে বিদ্যা দান, বিপন্নে আশ্রয়,</div>
            <div>রোগীরে ঔষধ দান, ভয়ার্তে অভয়,</div>
            <div>গৃহহীনে গৃহ দান, অন্ধেরে নয়ন,</div>
            <div>পীড়াতে আরোগ্য দান, শোকার্ত সান্তন,</div>
            <div>স্বার্থশূন্য হয় যদি এ দ্বাদশ দান,</div>
            <div>স্বর্গের দেবতা নহে দাতার সমান।</div>
            <div className="lastDesign"> - রজনীকান্ত সেন</div>
          </div>


        </div>

      </div>
    </div>
  );
}