
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import Menu from "./components/menu/Menu";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Topbar from "./components/topbar/Topbar";
import { useState } from "react";
import Works from "./components/works/Works"
import Footer from "./components/footer/Footer";

function App() {
  const [menuOpen,setMenuOpen] = useState(false)

  return (
    <div className="App">
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
     <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
     <div className="sections">

      <Intro/>
        <Portfolio/>
        <Works/>
        <Testimonials/> 
        <Contact/>
        <Footer/>
    </div>
    </div>

  );
}

export default App;
