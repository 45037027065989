import "./footer.scss";

export default function Footer() {

    return (
        <div className="footerD">
            <ul>
                <li>
                    <a href="#intro">সূচনা </a>
                </li>
                <li>
                    <a href="#portfolio">মন্দির সম্পর্কিত কাহিনী</a>
                </li>
                <li>
                    <a href="#works">ভিডিও</a>
                </li>
                <li>
                    <a href="#testimonials">মন্দির এর কিছু ছবি</a>
                </li>
                <li>
                    <a href="#contact">দান করুন</a>
                </li>
            </ul>
            <span className="spandiv">@Website developed by Indrajit Manna & Basudeb Basak</span>
        </div>
    );
}