import "./testimonials.scss";

export default function Testimonials() {

  const data = [
    {
      id: 1,
      img:
        "assets/img2.jpeg",
    },
    {
      id: 2,
      img:
        "assets/img3.jpeg",

    },
    {
      id: 3,
      img:
        "assets/img4.jpeg",
    }
  ];

  const data2 = [
    {
      id: 1,
      img:
        "assets/img10.jpg",
    },
    {
      id: 2,
      img:
        "assets/img11.jpg",

    },
    {
      id: 3,
      img:
        "assets/img12.jpg",
    }
  ];


  return (
    <div className="testimonials" id="testimonials">
      <h1 className="title1">মন্দির এর কিছু ছবি </h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img
                className="user"
                src={d.img}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      <div className="container">
        {data2.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img
                className="user"
                src={d.img}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}