import React from "react";
import Slider from "react-slick";
import "./contact.scss";

const images = [
  "assets/img1.jpeg",
  "assets/img2.jpeg",
  "assets/img3.jpeg",
  "assets/img4.jpeg",
  "assets/img5.jpeg",
  "assets/img10.jpg",
  "assets/img11.jpg",
  "assets/img12.jpg"
];

export default function Contact() {


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  

  return (
    <div className="contact" id="contact">
      <div className="left">
        {/* <img src="assets/img4.jpeg" alt="" /> */}
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} >
              <img src={img} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="right">
        
       <div className="div5Design">আমরা শতাব্দী প্রাচীন বাবা বনকুমার রুদ্রেশ্বর জিউর মন্দির পুনঃনির্মান কল্পে ব্রতী হইয়াছি , এই আধ্যাত্মিক ও পারমার্থিক সম্পাদনে আপনাদের সকল প্রকার দান/সাহায্য প্রার্থনা করি।</div>
          
        <h2>দান করুন</h2>
        <div className="bankDesign">
        <div className="bankDesign1">BANK NAME - PUNJAB NATIONAL BANK</div>
        <div className="bankDesign1">BRANCH NAME - ASNAN</div>

        <div className="bankDesign1">ACCOUNT HOLDER NAME - KALIKADARI BANKUMAR RUDRESWAR JIU</div>
        <div className="bankDesign1">AC/No - 1275200100015642</div>
        <div className="bankDesign2">IFC - PUNB0127520</div>
        </div>
        <h2>কমিটি সদস্য</h2>
        <div className="nameDesign">১)  সন্দীপন জানা - ৯৭৩২৭৮৬৯০৭ </div>
        <div className="nameDesign"> ২) সৌমেন সামন্ত - ৯৭৩৫৪৫৪০২৫ </div>
        <div className="nameDesign">৩) বানেশ্বর জানা - ৮০১৬৪৬৪০৭৬</div>
        <div className="nameDesign">৪) বাবুল পাত্র - ৯৭৩৩৮১৫৫৩৭ </div>
        <div className="nameDesign">৫) সৌদ্বীপ পাত্র - ৯৫৪৭৪১১২৬৬ </div>
        <div className="nameDesign">৬) সুমন পাত্র - ৮১৪৫৩৩৭২৭৫ </div>

      </div>
      <div className="last">
        <h2>অনলাইন এ দান করুন</h2>
      <img className="imgDesi" src="assets/scanner.jpeg"  alt="Scanner" />

      </div>
    </div>
  );
}