import "./topbar.scss";
export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            <img src="assets/updatelogo.png" alt="Logo" class="logo-img" />
          </a>
          <div className="itemContainer">
            <span>শ্রী শ্রী বাবা বনকুমার(রুদ্রেশ্বর) জীউর মন্দির</span>
          </div>
          <div className="itemContainer">
            <div className="address"> কালিকাদাঁড়ী , নৈছনপুর -১ গ্রাম পঞ্চায়েত , ময়না , পূর্ব মেদিনীপুর , পিন -৭২১৬৪২</div>
          </div>

          <div className="itemContainer">
            <a href="https://maps.google.com/?cid=770227652125868205&entry=gps&g_st=aw" target="_blank" rel="noopener noreferrer">

              <img src="assets/map.png" alt="Logo" class="logo-img" />
            </a>
          </div>
          <div className="itemContainer">
            <a href="https://www.facebook.com/profile.php?id=100064794520086&mibextid=JRoKGi" target="_blank" rel="noopener noreferrer">
              <img src="assets/fblogo.png" alt="Logo" className="logo-img" />
            </a>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}